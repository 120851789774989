.Home_Header {
  display: flex;
  flex-wrap: wrap;
  height: auto;
  
  .Home_Header_greeting_container {
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 24px;
    flex: 1 1 100%;
    img {
      width: 120px;
      height: 34px;
      display: block;
    }
    .Home_Header_greeting {
      height: 56px;
      display: flex;
      align-items: center;
      flex: 1 1 100%;
    }
    .kebab-menu {
      height: 30px;
      display: flex;
    }
    .Home_Header_greeting_Profile {
      flex: 1 1 13%;
      display: flex;
      .H_H_G_P_Img_Box {
        width: 32px;
        height: 32px;
        border-radius: 100%;
        padding: 2px;
        img {
          max-width: 100%;
          height: auto;
          display: block;
          border-radius: 100%;
        }
      }
    }
    .Home_Header_greeting_Text {
      flex: 1 1 87%;
      h5{
        @extend .h6;
        color: $charcoal;
      }
    }
  }

}