.matching-process {
    padding: 0 20px;

  .matching-process-title {
    padding: 24px 0 10px;
    border-bottom: 1px solid $grainsboro;
    display: flex;
    align-items: center;
    .partner-icon {
        width: 48px;
        height: 48px;
        img {
            border-radius: 50%;
            max-width: 100%;
            width: 100%;
            display: block;
            height: 100%;
        }
    }
    .booking-button-icon {
        position: relative;
        .kuya-headshot {
            width: 38px;
            height: 38px;
        }
        .icon {
            position: absolute;
            top: -10px;
            right: -13px;
            width: 20px;
            height: 24px;
        }
    }
    .title {
        // padding-left: 16px;
        display: flex;
        flex-direction: column;
        p {
            @extend .h6;
            margin-bottom: 0;
            color: $charcoal;
        }
        .text-secondary {
            color: $spanish-gray;
            font-weight: normal;
            font-size: 12px;
            line-height: 18px;
            letter-spacing: 0.4px;
        }
    }
  }
  .matching-process-container {
      padding: 32px 20px 40px 16px;
      .title {
        color: $charcoal;
        margin: 0 0 16px;
        font-weight: 600;
        font-size: 16px;
      }
      .matching-process-row {
        display: flex;
        align-items: center;
        margin-bottom: 4px;
        .note-partner {
            overflow: visible;
        }
        &.payment {
            padding-left: 2px;
            .billing-card-image.GCash {
                width: 15px;
                height: 13px;
            }
        }
        .billing-card-image.mc {
            width: 18px;
            height: 11px;
            + p {
                padding-left: 14px;
            }
        }
        .billing-card-image.visa {
            width: 20px;
            height: 12px;
            + p {
                padding-left: 13px;
            }
        }
        &.note {
            align-items: flex-start;
            svg {
                margin-top: 3px;
            }
            p {
                // overflow: visible;
                display: -webkit-box;
                max-width: 318px;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                overflow: hidden;
                height: 60px;
                text-overflow: ellipsis;
            }
        }
        p {
            margin-bottom: 0;
            @extend .body2;
            color: $charcoal;
            padding-left: 16px;
            height: 20px;
            overflow: hidden;
            width: 100%;
        }
        .icon-card {
            &.mc {
                width: 16px;
                height: 10px;
            }
            &.visa {
                width: 16px;
                height: 6px;
            }
        }
        .mykoin-image {
            width: 13px;
            height: 13px;
            padding: 1px;
            + p {
                padding-left: 19px;
            }
        }
      }
  }
  .tip {
      font-size: 12px;
      line-height: 18px;
      display: flex;
      align-items: center;
      letter-spacing: 0.4px;
      color: $spanish-gray;
      margin-bottom: 0;
  }
}
@media(max-width: 400px) {
    .matching-process .matching-process-container {
        padding: 40px 0;
    }
}