.Home_Parent_Box{
  position: relative;
  height: auto;
  z-index: 1;
}
.Home_Page{
  position: relative;
  left: 0;
  transition: left .6s ease-in-out;
}
.Search_Home_Box{
  width: 100%;
  height: 100vh;
  background: $doctor;
  position: fixed;
  right: -1000%;
  z-index: 999;
  transition: right .7s ease-in-out;
  @media (min-width: 770px) {
    position: fixed;
    max-width: 412px;
    margin: 0 auto;
  }
}
.Home_Page_Bottom_Sheet{
  display: flex;
  flex-wrap: wrap;
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 40px 40px 0 0;
  .Top_Four_Product{
    padding: 0 24px;
    flex: 1 1 100%;
    display: flex;
    height: auto;
    flex-wrap: wrap;
    margin: 16px 0;
    min-height: 80px;
  }
}
.Empty_Down_Description{
  color: #B1B3B7;
  text-align: left;
  margin: 0;
  font-size: 12px;
  span{
    &:nth-child(2){
      font-weight: bold;
      color:$bleu-de-france;
    }
  }
}
.Empty_Top_Product_Description{
  color: #B1B3B7;
  text-align: left;
  margin: 16px 8px 0 0;
  font-size: 12px;
  span{
    &:nth-child(2){
      font-weight: bold;
      color:$bleu-de-france;
    }
  }
}
.Animated_Location{
  right: auto !important;
  overflow-y: scroll;
  scroll-behavior: scroll;
  &::-webkit-scrollbar {
    width: 0;
  }
}
.Navigate_Blue_Box{
  display: flex;
  flex-direction: column;
  height: auto;
  right: 0;
  background: $bright-navy-blue;
}
.navigator-bottom-sheet {
  .indicator-container {
    position: relative;
    background: $bright-navy-blue;
  }
}
.Bottom_Sheet_Parent_Home_Page{
  position: relative;
  bottom: 0;
  width: 100%;
  height: auto;
  transition:right .55s ease-in-out;
  .react-swipeable-view-container{
    box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.1) !important;
    border-radius: 40px 40px 0 0 !important;
  }
  .ReactSwipeableBottomSheet--open  , .ReactSwipeableBottomSheet--closed{
    border-radius: 40px 40px 0 0 !important;
    &::-webkit-scrollbar {
      width: 0;
    }
  }
}
.Animated_HomePagePos{
  right: 0;
}
.Home_Tab_Panel{
  flex-wrap: wrap;
  animation-duration: .7s;
  //margin: 0 16px 16px;
  flex:1 1 100%;
  display: flex;
  width: 100%;
  float: left;
  position: relative;
}
.Right_Slide{
  animation-name: rightreducetime;
  animation-duration: .7s;
}
.Left_Slide{
  animation-name: leftreducetime;
  animation-duration: .7s;
}
@keyframes rightreducetime {
  0% {
    right: -100%;
  }
  100% {
    right: 0;
  }
}
@keyframes leftreducetime {
  0% {
    left: -100%;
  }
  100% {
    left: 0;
  }
}


.Active_panel_Parent{
  left: 0;
}
.slick-slider{
  max-width: 100%;
  overflow: hidden;
}
.Empty_Top_Four_Btn {
  background-color: $doctor !important;
  color: $bleu-de-france !important;
  border: 1px solid $bleu-de-france !important;
  outline: none;
  box-shadow: none;
  width: 100%;
  border-radius: 3px;
  padding: 8px !important;
  @extend .button;
  text-transform: none;
  font-weight: bold !important;
  margin: 8px 0 0 0 !important;
  height: 40px;
  font-size: 16px !important;
  .Dynamic_Parent_Icon{
    height: auto !important;
  }
  .Btn_Text{
    text-transform: none !important;
  }
  &:disabled {
    background-color: #C5C5C5 !important;
  }
}
.Home_Empty_Top_Product{
  width: 100%;
}
.Gray_Middle_Line{
  width: 40px;
  height: 4px;
  background: #DBDBDB;
  margin: -13px auto;
  border-radius: 20px;
}
.Black_Middle_Line{
  width: 128px;
  height: 4px;
  background: black;
  padding: 25px auto 0 auto;
  margin: 26px auto -12px auto;
  border-radius: 23px;
}
@media screen and (device-aspect-ratio: 40/71) {
  .Black_Middle_Line{
    margin: 16px auto -12px auto !important;
  }
  .Home_Featured_Box{
    min-height: 240px !important;
  }
  .Empty_Down_Description{
    font-size: 11px;
  }

}
.products-container {
  flex: 1 1 100%;
  width: 100%;
  // min-height: 312px;
  overflow: hidden;
  background-color: $saripay-primary;
  position: relative;
  .rec-right {
    position: absolute;
    right: 0;
    top: 100px;
  }
  .home-bottom {
    position: absolute;
    bottom: 0;
    right: 0;
  }
  .bottom-ellipse {
    position: absolute;
    bottom: 0;
    left: 80px;
  }
  .shop-name {
    width: 100%;
    padding: 8px 20px 0;
    h1 {
      border-bottom: 1px solid $silver-chalice;
      @extend .h5;
      margin: 0;
      padding-bottom: 8px;
      color: $doctor;
      text-align: center;
    }
  }
}
