.billing-information-add {
    padding: 24px 20px;
    display: flex;
    justify-content: flex-end;
    .button-container {
        &.MuiButtonBase-root {
            &.MuiButton-root {
                font-style: normal;
                font-weight: 600;
                font-size: 12px;
                line-height: 18px;
                letter-spacing: 0.4px;
                padding: 0;
                color: $saripay-primary;
                background: transparent;
                box-shadow: none;
                width: fit-content;
                text-transform: inherit;
                border: 0;
            }
        }

    }
}