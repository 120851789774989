.Home_Empty_Services {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  // min-height: 312px;
  flex: 1 1 100%;
  flex-direction: column;
  // background: url('../../../assests/images/home/ServiceCollectionBG.svg') $bright-navy-blue 70% 68% no-repeat;
}

.Home_Empty_Services {
  .Dynamic_Parent_Icon {
    width: 100%;
    height: 100%;
    margin: 0px auto 0 auto;
  }
}

.Home_Search_Empty {
  margin: 24px auto 0px auto;
}

.Empty_Description {
  margin: 8px auto 0 auto;
  color: #fff;
  text-align: center;
  font-size: 12px;
}

.Empty_Service_Btn {
  background-color: $doctor !important;
  color: $saripay-primary !important;
  outline: none;
  box-shadow: none;
  border: none;
  width: 100%;
  border-radius: 5px;
  padding: 8px;
  @extend .button;
  text-transform: none;
  font-weight: 600 !important;
  margin: 0 24px !important;
  height: 46px;
  font-size: 18px !important;
  line-height: 27px;
  .Dynamic_Parent_Icon {
    height: auto !important;
  }
  .Btn_Text {
    text-transform: none !important;
  }
  .Location_Pin {
    //position: absolute;
    //top: 10px;
    //left: 64px;
    //width: 18px;
    //height: 18px;
  }
  &:disabled {
    background-color: #C5C5C5 !important;
  }
}

.Location_Pin_Empty {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

.Top_Four_Empty {
  display: flex;
  flex-wrap: wrap;
  flex: 1 1 100%;
  width: 100%;
  .Empty_Down_Description {
    margin-top: 8px;
    @extend .caption;
    color: $silver-chalice;
    margin: 0;
    span {
      color: $saripay-primary;
    }
  }
}

.MuiButton-root.Empty_Top_Four {
  background-color: $doctor !important;
  color: $saripay-primary !important;
  outline: none;
  border: 1px solid $saripay-primary !important;
  border-radius: 8px;
  box-shadow: none;
  width: 70%;
  border-radius: 5px;
  padding: 8px !important;
  @extend .button;
  text-transform: none;
  margin: 8px auto;
  height: 46px;
  font-size: 18px !important;
  letter-spacing: 0.25px;
  svg {
    width: 30px;
    height: 30px;
  }
  &:disabled {
    background-color: #C5C5C5 !important;
  }
}