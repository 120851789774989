.Blue_Box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: $saripay-primary;
  height: 10%;
  padding: 20px;
  .See_Job_Right {
    flex-basis: 60%;
    max-width: 60%;
  }
  .See_Job_Left {
    flex-basis: 40%;
    max-width: 40%;
    padding-right: 16px;
    .Total_Amount_Paid {
      color: #E2EEF8;
      font-size: 12px;
      text-align: right;
      margin: 0;
      line-height: 18px;
    }
    .Total_Amount_Cost {
      color: $doctor;
      font-size: 19px;
      font-weight: 600;
      margin: 0;
      text-align: right;
    }
  }
}

.See_Job_Receipt_Btn {
  width: 100%;
  &.MuiButtonBase-root {
    background-color: $doctor !important;
    color: $saripay-primary !important;
    width: 100%;
    text-transform: none;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.25px;
    padding: 10px;
    z-index: 1;
    &:hover, &:focus, &:active {
      background-color: $doctor !important;
      color: $saripay-primary !important;
    }
  }
  .button-container.MuiButtonBase-root.MuiButton-root {
    padding: 10px;
    padding: 8px;
  }
}
