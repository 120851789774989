.billing-information-safely {
  background-color: $saripay-primary;
  height: 124px;
  padding: 0 20px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  z-index: 2;
  width: 100%;
  bottom: 0;
  right: 0;
  left: 0;

  .billing-information-outer-text {
    position: absolute;
    top: -30px;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: $charcoal;
  }

  .billing-information-safely-inner {
    display: flex;
    height: 36px;
    align-items: center;
    .billing-information-safely-image {
        width: 30px;
        img {
            max-width: 100%;
            height: auto;
            display: block;
            margin: 0 auto;
        }
    }
  }

  p {
    @extend .body2;
    color: $doctor;
    margin: 0 0 0 8px;
  }
}