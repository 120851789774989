.review-booking-button-container {
    padding: 24px 16px;
    bottom: 0;
    width: 100%;
    background-color: $saripay-primary;
    display: flex;
    align-items: center;
    position: fixed;
    width: 100%;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    margin: 0 auto;
    @media (min-width: 770px) {
        max-width: 414px;
    }
    .review-booking-button  {
        flex-basis: 70%;
        max-width: 70%;
        .button-container {
            &.MuiButton-root {
                width: 100%;
                background-color: $doctor;
                border-radius: 5px;
                color: $saripay-primary;
                @extend .button;
                padding: 8px;
                text-transform: none;
                letter-spacing: .25px;
            }
        }
    }
    .price-container {
        flex-basis: 30%;
        max-width: 30%; 
        color: $doctor;
        padding-right: 16px;
        align-items: flex-end;
        display: flex;
        flex-direction: column;
       p {
           margin-bottom: 0;
           @extend .caption;
           &.price {
               @extend .h6;
           }
       }
    }
}