.welcome-container {
    height: 100%;
    width: 100%;
    .logo-container {
        padding: 20px 20px 5px;
        img {
            // border-radius: 50%;
            width: 100px;
            // height: 44px;
        }
    }
    h1 {
        color: $charcoal;
        margin: 0 0 8px;
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 36px;
        padding: 0 20px;
    }
    h2 {
        font-style: normal;
        font-weight: 600;
        font-size: 19px;
        line-height: 28px;
        padding: 0 20px;
        margin: 0 0 40px;
        color: $spanish-gray;
    }
    h5 {
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 22px;
        letter-spacing: 0.5px;
        color: $charcoal;
        // padding: 0 20px 190px;
    }
    .shopping-image {
        padding: 5px 8px;
        margin-bottom: 8px;
        width: 90%;
        margin: 0 auto 30px;
        img {
            display: block;
            max-width: 100%;
            height: auto;
        }
    }
    .horizontal-border {
        width: 100%;         
        margin: 20px 0;
        h6 {
            text-align: center; 
            border-bottom: 1px solid $grainsboro; 
            line-height: 0.1em;
            span {
                background:$doctor; 
                padding:0 12px;
                color: $spanish-gray;
            }
        }
    }
    .ReactSwipeableBottomSheet--open {
        scrollbar-width: none;
        &::-webkit-scrollbar {
        width: 0;
        }
    }
    .welcome-call-to-actions {
        position: fixed;
        width: 100%;
        bottom: 0;
        padding: 20px;
        background-color: $doctor;
        @media (min-width: 770px) {
            max-width: 412px;
        }
    }
}
@media(max-height: 730px) {
    .welcome-container {
        h2 {
            margin-bottom: 12px;
        }
        .shopping-image {
            width: 85%;
            // margin: 0 auto;
        }
        .welcome-call-to-actions {
            padding-top: 0;
        }
    }
}
@media(max-height: 692px) {
    .welcome-container {
        h2 {
            margin-bottom: 8px;
        }
        .shopping-image {
            width: 70%;
            // margin: 0 auto;
        }
    }
}
@media(max-height: 655px) {
    .welcome-container {
        h2 {
            margin-bottom: 0px;
        }
        .shopping-image {
            width: 68%;
            // margin: 0 auto;
        }
    }
}